import React, { Children, useState } from 'react'

import IconButton from '@material-ui/core/IconButton'
import { useTheme } from '@material-ui/core/styles'

import NextIcon from 'icons/NextIcon'
import PrevIcon from 'icons/PrevIcon'

export default function Carousel({ children }) {
  const { palette, spacing } = useTheme()
  const [index, setIndex] = useState(0)

  return (
    <div style={{ position: 'relative', overflow: 'hidden' }}>
      <div
        style={{
          padding: '0 70px',
          display: 'flex',
          gap: spacing(3),
          transform: `translateX(${index * -200}px)`,
          transitionDuration: '0.3s',
          transitionTimingFunction: 'ease-in-out',
        }}
      >
        {children}
      </div>
      {index > 0 && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: spacing(3),
            transform: 'translateY(-50%)',
            zIndex: 100,
          }}
        >
          <IconButton style={{ padding: 0 }} onClick={() => setIndex(index - 1)}>
            <PrevIcon color={palette.primary.main} />
          </IconButton>
        </div>
      )}
      {index + 1 < Children.count(children) && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            right: spacing(3),
            transform: 'translateY(-50%)',
            zIndex: 100,
          }}
        >
          <IconButton style={{ padding: 0 }} onClick={() => setIndex(index + 1)}>
            <NextIcon color={palette.primary.main} />
          </IconButton>
        </div>
      )}
    </div>
  )
}
