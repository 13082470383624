import React from 'react'

import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import SectionHeader from 'components/SectionHeader'

export default function Policy() {
  const { palette, spacing } = useTheme()

  const H3 = ({ children }) => {
    return (
      <Typography style={{ padding: spacing(3, 0) }} variant="h3">
        {children}
      </Typography>
    )
  }

  const P = ({ children }) => {
    return (
      <Typography style={{ marginBottom: spacing(6) }} variant="body2">
        {children}
      </Typography>
    )
  }

  return (
    <div style={{ background: palette.background.paper }}>
      <SectionHeader primary="個人情報保護方針 | プライバシーポリシー" />
      <div style={{ padding: spacing(3) }}>
        <P>
          株式会社Runtrip（以下「当社」という。）は、お客様の個人情報保護の重要性を強く認識し、またお客様との信頼関係を今後も恒久的に築いて行くため、以下の通りプライバシーポリシーを定め、お客様の個人情報の適切な保護に努めます。
        </P>
        <H3>1.法令等の遵守</H3>
        <P>
          当社は、個人情報の取扱いについて、『個人情報の保護に関する法律』、『電気通信事業法』、その他個人情報保護関連法令及びガイドライン、またこのプライバシーポリシーを遵守します。
        </P>
        <H3>2.個人情報の定義</H3>
        <P>
          個人情報とは、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、その他の記述等により特定の個人を識別することが出来るものを言います。これには他の情報と照合することが出来、それにより特定の個人を識別出来るものを含みます。
        </P>
        <H3>3条 会員登録</H3>
        <P>
          当社では、個人情報の取得に際しては、本プライバシーポリシー内または各サービスのウェブサイト内において、あらかじめ利用目的をできる限り特定した上で公表します。また、公表した利用目的にしたがって個人情報を取り扱います。
          ただし、当社は、アプリにより取得したHealthKitデータを、マーケティング、広告又はこれらに類似した目的で利用することはありません。また、当社は、アプリにより取得したHealthKitデータを第三者に提供いたしません。当社で取得する個人情報の利用目的は以下の通りです。
        </P>
        <P>
          ①当社サービスをご利用いただいた方又は当社サービスにご関心をお持ちの方（以下「お客様」といいます。）の個人情報の利用目的
        </P>
        <div style={{ padding: spacing(0, 0, 3, 3) }}>
          <Typography variant="body2">
            1号 利用希望者が本規約に違反するおそれがあると当社が判断した場合
          </Typography>
          <Typography variant="body2">
            2号
            1項の規定による申込みに当たって当社に提供された情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合
          </Typography>
          <Typography variant="body2">
            3号 会員希望者が過去当社との契約に違反した者又はその関係者であると当社が判断した場合
          </Typography>
          <Typography variant="body2">4号 会員希望者が2０歳未満の未成年である場合</Typography>
          <Typography variant="body2">
            5号
            会員希望者が成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人､保佐人又は補助人の同意等を得ていなかった場合
          </Typography>
          <Typography variant="body2">
            6号
            会員希望者が、反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力その他これに準ずる者をいいます。以下同じ。）であり、又は資金提供その他の手段を通じて反社会的勢力等の維持、運営若しくは経営に協力し、若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合
          </Typography>
          <Typography variant="body2">
            7号 前各号に掲げるもののほか、当社が入会の承諾をすることが適当でないと判断した場合
          </Typography>
        </div>
        <P>
          ※
          なお、各サービスの利用に際しては、お客様の意思によって利用される個人情報と連動した機能の取り扱いには格別のご注意をいただきますようお願いいたします。
        </P>
      </div>
    </div>
  )
}
