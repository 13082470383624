import React from 'react'

export default function PrevIcon({ color }) {
  const background = color
    .slice(1)
    .match(/.{2}/g)
    .map(x => {
      const num = parseInt(x, 16)
      return Math.round(255 - (255 - num) * 0.16).toString(16)
    })
    .join('')
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="21" cy="21" r="21" fill={`#${background}`} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4854 29.9705C23.7044 30.7516 22.438 30.7516 21.657 29.9705L14.5859 22.8994C13.8049 22.1184 13.8049 20.8521 14.5859 20.071L21.657 12.9999C22.438 12.2189 23.7044 12.2189 24.4854 12.9999C25.2665 13.781 25.2665 15.0473 24.4854 15.8284L18.8286 21.4852L24.4854 27.1421C25.2665 27.9231 25.2665 29.1895 24.4854 29.9705Z"
        fill={color}
      />
    </svg>
  )
}
