import React from 'react'

import IconButton from '@material-ui/core/IconButton'
import { useTheme } from '@material-ui/core/styles'

import { ReactComponent as FacebookIcon } from 'images/icons/facebook.svg'
import { ReactComponent as LINEIcon } from 'images/icons/line.svg'
import { ReactComponent as TwitterIcon } from 'images/icons/twitter.svg'

export default function Event() {
  const { spacing, palette } = useTheme()
  const url = window.location.href

  return (
    <div
      style={{
        background: palette.background.paper,
        padding: spacing(3),
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
      }}
    >
      <IconButton
        onClick={() => window.open(`https://social-plugins.line.me/lineit/share?url=${url}`)}
        style={{ marginLeft: spacing(6), padding: 4, background: '#00b900' }}
      >
        <LINEIcon />
      </IconButton>
      <IconButton
        onClick={() =>
          window.open(
            `https://twitter.com/intent/tweet?text=${encodeURIComponent(
              process.env.REACT_APP_TWITTER_TEXT
            )}&url=${url}&hashtags=${process.env.REACT_APP_TWITTER_HASHTAGS}`
          )
        }
        style={{ marginLeft: spacing(6), padding: 4, background: '#55acee' }}
      >
        <TwitterIcon />
      </IconButton>
      <IconButton
        onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`)}
        style={{ marginLeft: spacing(6), padding: 4, background: '#3b5998' }}
      >
        <FacebookIcon />
      </IconButton>
    </div>
  )
}
