import React from 'react'

import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import MultilineText from 'components/MultilineText'
import SectionHeader from 'components/SectionHeader'

export default function FAQ() {
  const { palette, spacing } = useTheme()

  if (!Boolean(process.env.REACT_APP_FAQ)) {
    return null
  }

  const items = JSON.parse(process.env.REACT_APP_FAQ)

  const Item = ({ question, answer, link }) => (
    <div style={{ borderBottom: `1px solid ${palette.divider}`, marginBottom: spacing(3) }}>
      <Typography style={{ fontWeight: 'bold', marginBottom: spacing(3) }} variant="body1">
        {question}
      </Typography>
      <Typography style={{ marginBottom: spacing(3) }} variant="body1">
        <MultilineText>{answer}</MultilineText>
      </Typography>
      {link && (
        <a
          style={{ display: 'block', color: palette.link, marginBottom: spacing(3) }}
          href={link.href}
        >
          {link.text}
        </a>
      )}
    </div>
  )

  return (
    <div
      style={{
        background: palette.background.paper,
        marginBottom: spacing(6),
        paddingBottom: spacing(6),
      }}
    >
      <SectionHeader primary="Q&A" />
      <div style={{ padding: spacing(6) }}>
        {items.map((props, i) => (
          <Item key={i} {...props} />
        ))}
      </div>
    </div>
  )
}
