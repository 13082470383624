import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

import CustomButton from 'components/CustomButton'
import Markdown from 'components/Markdown'
import MultilineText from 'components/MultilineText'
import { useEvent } from 'hooks/useEvent'
import placeholder from 'images/placeholder.png'

export default function Certificate() {
  const { push } = useHistory()
  const { id } = useParams()
  const { palette, spacing } = useTheme()
  const { getEvent } = useEvent()
  const [eventResource, setEventResource] = useState(null)
  const { event, activity, privileges } = eventResource || {}

  useEffect(() => {
    getEvent(id).then(setEventResource)
  }, [getEvent, id])

  if (!eventResource) {
    return null
  }

  if (!activity.uploaded) {
    push('/')
  }

  return (
    <div style={{ background: palette.background.paper, padding: spacing(6), textAlign: 'center' }}>
      <img
        style={{ maxWidth: 320, width: '100%', height: 'auto' }}
        alt="完走証"
        src={activity.finisher_certificate_image_url}
        onError={event => (event.target.src = placeholder)}
      />
      <form method="GET" action={activity.finisher_certificate_image_url}>
        <CustomButton type="submit" style={{ marginTop: spacing(6) }} arrow>
          完走証のダウンロード
        </CustomButton>
      </form>
      {privileges && privileges.length > 0 && (
        <CustomButton
          style={{ marginTop: spacing(3) }}
          onClick={() => push(`/events/${event.id}/privileges/me`)}
          arrow
        >
          取得した完走特典({privileges.length})
        </CustomButton>
      )}
      {process.env.REACT_APP_WEB_TO_APP_TITLE && (
        <>
          <br />
          <div style={{ width: '100%', height: 1, background: palette.divider }} />
          <Typography
            variant="body1"
            align="center"
            style={{ fontWeight: 'bold', marginTop: spacing(3), marginBottom: spacing(3) }}
          >
            <MultilineText>{process.env.REACT_APP_WEB_TO_APP_TITLE}</MultilineText>
          </Typography>
          <Markdown style={{ marginBottom: spacing(3) }}>
            {process.env.REACT_APP_WEB_TO_APP_DESCRIPTION}
          </Markdown>
          <Typography
            style={{ color: palette.link, cursor: 'pointer' }}
            variant="body2"
            onClick={() => window.open(process.env.REACT_APP_WEB_TO_APP_HOW_TO_USE_LINK)}
          >
            使い方はこちら
          </Typography>
          <CustomButton
            style={{ marginTop: spacing(3) }}
            onClick={() => window.open(process.env.REACT_APP_WEB_TO_APP_LINK)}
            arrow
          >
            アプリを開く
          </CustomButton>
        </>
      )}
    </div>
  )
}
