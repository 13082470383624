import React from 'react'

import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import MultilineText from 'components/MultilineText'

export default function AboutUs() {
  const { spacing, palette } = useTheme()

  if (!Boolean(process.env.REACT_APP_ABOUT_US)) {
    return null
  }

  const { image, sponcers, description } = JSON.parse(process.env.REACT_APP_ABOUT_US)
  const Sponcer = ({ label, sponcers }) => (
    <Typography style={{ color: '#fff' }} varinat="body1">
      <span>{label}：</span>
      {sponcers.map(({ text, href }, i) => (
        <span key={i}>
          {href.length > 0 ? (
            <a style={{ color: '#fff', textDecoration: 'underline' }} href={href}>
              {text}
            </a>
          ) : (
            <span style={{ color: '#fff' }}>{text}</span>
          )}
          {i < sponcers.length - 1 && '、'}
        </span>
      ))}
    </Typography>
  )

  return (
    <div
      style={{ background: palette.primary.main, margin: spacing(6, 0), padding: spacing(6, 3) }}
    >
      <Typography variant="h2" style={{ fontSize: 24, color: '#fff', marginBottom: spacing(6) }}>
        ABOUT US
      </Typography>
      {description && (
        <Typography style={{ marginBottom: spacing(6), color: '#fff' }} variant="body1">
          <MultilineText>{description}</MultilineText>
        </Typography>
      )}
      {sponcers.map(x => (
        <Sponcer key={x.label} {...x} />
      ))}
      {image && (
        <div style={{ textAlign: 'center', marginTop: spacing(6) }}>
          <img src={image} alt="Sponcer" style={{ width: '100%', maxWidth: 300 }} />
        </div>
      )}
    </div>
  )
}
