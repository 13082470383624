import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import { useTheme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

import Popup from 'components/Popup'
import { useAPI } from 'hooks/useAPI'

export default function CouponCodeInput({ onSuccess }) {
  const [error, setError] = useState(null)
  const [code, setCode] = useState('')
  const { id } = useParams()
  const { spacing } = useTheme()
  const { client, requesting } = useAPI()

  const handleClick = () => {
    client
      .get(`/vrwc/events/${id}/coupons/${code.trim()}`)
      .then(res => onSuccess(res.data))
      .catch(setError)
  }

  return (
    <>
      <div
        style={{
          margin: spacing(2, 0),
          display: 'flex',
          alignItems: 'flex-end',
          borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        }}
      >
        <TextField
          style={{ flex: 1 }}
          color="secondary"
          name="coupon"
          type="string"
          label="クーポンコード"
          placeholder="abcd"
          onChange={e => setCode(e.target.value)}
          InputProps={{ disableUnderline: true }}
          InputLabelProps={{ shrink: true }}
          error={error !== null}
          helperText={error && error.message}
        />
        <Button
          variant="contained"
          disabled={code === '' || requesting}
          disableElevation={true}
          style={{
            marginBottom: 6,
            padding: '2px 12px',
            borderRadius: 13,
            background: '#eee',
            fontSize: 12,
          }}
          onClick={handleClick}
        >
          適用する
        </Button>
      </div>
      <Popup
        open={error !== null}
        onClose={() => setError(null)}
        title="エラー"
        description={
          <>
            クーポンコードが有効ではありません。次のいずれかの原因が考えられます。
            <br />
            ・クーポンコードの入力間違い
            <br />
            ・有効期限切れ
            <br />
            ・対象イベントなど利用条件に該当しない
          </>
        }
        primaryText="OK"
        onClickPrimary={() => setError(null)}
      />
    </>
  )
}
