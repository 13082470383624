import React from 'react'

export default function NextIcon({ color }) {
  const background = color
    .slice(1)
    .match(/.{2}/g)
    .map(x => {
      const num = parseInt(x, 16)
      return Math.round(255 - (255 - num) * 0.16).toString(16)
    })
    .join('')

  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="21" cy="21" r="21" fill={`#${background}`} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6572 12.9999C18.4382 12.2189 19.7045 12.2189 20.4856 12.9999L27.5566 20.071C28.3377 20.8521 28.3377 22.1184 27.5566 22.8994L20.4856 29.9705C19.7045 30.7516 18.4382 30.7516 17.6572 29.9705C16.8761 29.1895 16.8761 27.9231 17.6572 27.1421L23.314 21.4852L17.6572 15.8284C16.8761 15.0473 16.8761 13.781 17.6572 12.9999Z"
        fill={color}
      />
    </svg>
  )
}
