import React from 'react'

export default function FinisherIcon({ color }) {
  return (
    <svg width='95' height='60' viewBox="0 0 112.54 149.38" xmlns="http://www.w3.org/2000/svg">
        <rect width="112.54" height="149.38" fill={color}/>
        <rect x="7.35" y="79.62" width="98.33" height="61.63" fill={`#fff`}/>
        <rect x="26" y="97.07" width="60.07" height="2.58" fill={color}/>
        <rect x="26" y="105.68" width="60.07" height="2.58" fill={color}/>
        <rect x="26" y="114.29" width="60.07" height="2.58" fill={color}/>
        <rect x="26" y="122.9" width="60.07" height="2.58" fill={color}/>
        <path d="M12.27,45.55a15.66,15.66,0,0,0,.13,2.69H9.08a16.71,16.71,0,0,0,.14-2.64v-8a15.67,15.67,0,0,0-.13-2.25c.63,0,1.07,0,2.26,0h4.46a18.9,18.9,0,0,0,2.59-.1v2.93a20.26,20.26,0,0,0-2.58-.11H12.27v2.53h2.91a21.86,21.86,0,0,0,2.47-.1v2.85a21.75,21.75,0,0,0-2.47-.11H12.27Z" fill={`#fff`}/>
        <path d="M25,35.39a14.82,14.82,0,0,0-.12,2.29V46A15.34,15.34,0,0,0,25,48.24h-3.4A15.17,15.17,0,0,0,21.75,46V37.68a14.9,14.9,0,0,0-.12-2.29Z"  fill={`#fff`}/>
        <path d="M36.25,41.71a19.66,19.66,0,0,1,1.12,1.84,22.73,22.73,0,0,1-.1-2.29V37.74a17.32,17.32,0,0,0-.12-2.35h3.29a17.22,17.22,0,0,0-.13,2.35v8.2a18.24,18.24,0,0,0,.13,2.3H37.06a17.21,17.21,0,0,0-1.16-2L33,42a17.45,17.45,0,0,1-1.11-1.9,21.49,21.49,0,0,1,.11,2.3v3.46a17.34,17.34,0,0,0,.12,2.41H28.87A16,16,0,0,0,29,45.82V37.69a17.65,17.65,0,0,0-.13-2.3h3.35a10.54,10.54,0,0,0,1.1,1.93Z" fill={`#fff`}/>
        <path d="M47.7,35.39a13.42,13.42,0,0,0-.13,2.29V46a13.89,13.89,0,0,0,.13,2.28H44.3A15.17,15.17,0,0,0,44.42,46V37.68a14.9,14.9,0,0,0-.12-2.29Z" fill={`#fff`}/>
        <path d="M54.37,44.06a2.05,2.05,0,0,0,.4,1.14,2.26,2.26,0,0,0,1.8.73c1.19,0,1.9-.53,1.9-1.39a1.17,1.17,0,0,0-.64-1.09,13.13,13.13,0,0,0-2.32-.63A6.06,6.06,0,0,1,53,41.93a3.49,3.49,0,0,1-1.49-3c0-2.38,1.91-3.88,4.95-3.88a5,5,0,0,1,4.29,1.77,4.18,4.18,0,0,1,.78,1.85l-3.28.35c-.18-1-.74-1.42-1.91-1.42-.94,0-1.64.51-1.64,1.22a1.05,1.05,0,0,0,.73,1,9.15,9.15,0,0,0,2.13.51,7.56,7.56,0,0,1,2.5.88,3.46,3.46,0,0,1,1.67,3.15,3.85,3.85,0,0,1-1.08,2.84,5.65,5.65,0,0,1-4.06,1.31,7.34,7.34,0,0,1-3.45-.75,3.69,3.69,0,0,1-1.67-2,6,6,0,0,1-.34-1.43Z" fill={`#fff`}/>
        <path d="M76.87,35.39a13.8,13.8,0,0,0-.13,2.29V46a15.25,15.25,0,0,0,.13,2.28H73.54A13.89,13.89,0,0,0,73.67,46V43H68.46v3a16,16,0,0,0,.13,2.28H65.27A15,15,0,0,0,65.39,46V37.68a15.58,15.58,0,0,0-.12-2.29h3.32a17,17,0,0,0-.13,2.29v2.56h5.21V37.68a13.65,13.65,0,0,0-.13-2.29Z" fill={`#fff`}/>
        <path d="M90.41,48.35a19.32,19.32,0,0,0-2.55-.11H83c-1.17,0-1.6,0-2.27,0A13.32,13.32,0,0,0,80.85,46V37.6a15.08,15.08,0,0,0-.13-2.25c.64,0,1.07,0,2.27,0h4.93a20.74,20.74,0,0,0,2.3-.1v2.88a20.87,20.87,0,0,0-2.3-.1h-4v2.26h3.33a19.17,19.17,0,0,0,2.32-.1v2.85a21,21,0,0,0-2.32-.1H83.91v2.59h4a20.41,20.41,0,0,0,2.52-.11Z" fill={`#fff`}/>
        <path d="M101.71,48.24a14.81,14.81,0,0,0-.76-2.41c-.49-1.34-.68-1.7-1.06-2a1.71,1.71,0,0,0-1.21-.41H97.11v2.53a16.72,16.72,0,0,0,.12,2.33H93.91A15.38,15.38,0,0,0,94,45.93V37.62a16.76,16.76,0,0,0-.13-2.27c.68,0,1.25,0,2.3,0h4c2.5,0,4,1.3,4,3.52a3.38,3.38,0,0,1-.91,2.54,3.52,3.52,0,0,1-1.47.77c.79.25,1.15.6,1.64,1.7.23.49.45,1,1.14,2.68.17.44.36.82.74,1.64Zm-2.19-7.5A1.34,1.34,0,0,0,101,39.3c0-.86-.49-1.31-1.45-1.31H97.11v2.75Z" fill={`#fff`}/>
        <rect x="8.41" y="26.28" width="96.95" height="1.75" fill={`#fff`}/>
        <rect x="8.41" y="55.6" width="96.95" height="1.75" fill={`#fff`}/>
    </svg>
  )
}
