import React, { useCallback, useMemo } from 'react'

import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'

export default function ActivityResult({ activity }) {
  const { spacing } = useTheme()
  const start = moment(activity.start_datetime)
  const end = moment(activity.end_datetime)
  const diff = end.diff(start) / 1000
  const zeroPadding = useCallback(num => ('0' + num).slice(-2), [])

  const time = useMemo(() => {
    const HH = zeroPadding(Math.floor(diff / (60 * 60)))
    const mm = zeroPadding(Math.floor((diff % (60 * 60)) / 60))
    const ss = zeroPadding(Math.floor(diff % 60))
    return `${HH}:${mm}:${ss}`
  }, [diff, zeroPadding])

  const lapTime = useMemo(() => {
    const km = activity.distance / 1000
    const lap = diff / km
    const mm = zeroPadding(Math.floor(lap / 60))
    const ss = zeroPadding(Math.floor(lap % 60))
    return `${mm}'${ss}"`
  }, [activity, diff, zeroPadding])

  const distance = useMemo(() => {
    return `${Math.floor(activity.distance / 100) / 10}km`
  }, [activity])

  return (
    <div style={{ flex: 1, paddingRight: spacing(4) }}>
      <Typography variant="caption" component="p">
        {start.format('YYYY/MM/DD(ddd) HH:mm')}-{end.format('HH:mm')}
      </Typography>
      <Typography variant="caption" component="p">
        {distance} {time} / {lapTime}
      </Typography>
    </div>
  )
}
