import React from 'react'

import { useTheme } from '@material-ui/core/styles'

import ActivityResult from 'components/ActivityResult'
import CustomButton from 'components/CustomButton'

export default function ActivityListItem({ activity, onClick }) {
  const { palette, spacing } = useTheme()

  return (
    <div
      style={{ display: 'flex', padding: spacing(4), borderBottom: `1px solid ${palette.divider}` }}
    >
      <ActivityResult activity={activity} />
      <CustomButton onClick={onClick} style={{ width: 72 }}>
        選択
      </CustomButton>
    </div>
  )
}
