import React from 'react'

export default function LineLink({color}) {
    return (
        <svg height='60' viewBox="0 0 162.84 114.51" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path
                    d="m57.17,114.51H11.31c-6.22,0-11.31-5.09-11.31-11.31V11.31C0,5.09,5.09,0,11.31,0h45.86c6.22,0,11.31,5.09,11.31,11.31v91.9c0,6.22-5.09,11.31-11.31,11.31Zm3.81-101.05H6.67v75.9h54.31V13.46Zm-27.16,83.91c-2.84,0-5.15,2.31-5.15,5.15s2.31,5.15,5.15,5.15,5.15-2.31,5.15-5.15-2.31-5.15-5.15-5.15Z"
                    fill={color}/>
                <g>
                    <path
                        d="m27.48,68.05l17.85-32.46c-1.85-1.84-4.12-3.22-6.62-4l-17.84,32.44c1.85,1.84,4.11,3.23,6.62,4.02Z"
                        fill={color}/>
                    <path
                        d="m50.94,45.81H15.24c-.29,1.29-.45,2.63-.45,4s.16,2.73.45,4.02h35.7c.29-1.29.45-2.64.45-4.02s-.16-2.72-.45-4Z"
                        fill={color}/>
                    <path
                        d="m45.32,64.04L27.48,31.58c-2.51.79-4.77,2.18-6.62,4.02l17.84,32.44c2.51-.78,4.77-2.17,6.62-4Z"
                        fill={color}/>
                    <g>
                        <g>
                            <polygon points="35.3 45.81 33.09 41.79 30.89 45.81 35.3 45.81" fill={color}/>
                            <polygon points="30.89 53.83 33.09 57.85 35.3 53.83 30.89 53.83" fill={color}/>
                        </g>
                        <g>
                            <polygon points="30.89 45.81 26.47 45.81 28.68 49.82 30.89 45.81" fill={color}/>
                            <polygon points="35.3 53.83 39.71 53.83 37.51 49.82 35.3 53.83" fill={color}/>
                        </g>
                        <polygon
                            points="28.68 49.82 30.89 53.83 35.3 53.83 37.51 49.82 35.3 45.81 30.89 45.81 28.68 49.82"
                            fill={color}/>
                        <g>
                            <polygon points="37.51 49.82 39.71 45.81 35.3 45.81 37.51 49.82" fill={color}/>
                            <polygon points="28.68 49.82 26.47 53.83 30.89 53.83 28.68 49.82" fill={color}/>
                        </g>
                    </g>
                </g>
                <path
                    d="m151.53,114.51h-45.86c-6.22,0-11.31-5.09-11.31-11.31V11.31c0-6.22,5.09-11.31,11.31-11.31h45.86c6.22,0,11.31,5.09,11.31,11.31v91.9c0,6.22-5.09,11.31-11.31,11.31Zm3.81-101.05h-54.31v75.9h54.31V13.46Zm-27.16,83.91c-2.84,0-5.15,2.31-5.15,5.15s2.31,5.15,5.15,5.15,5.15-2.31,5.15-5.15-2.31-5.15-5.15-5.15Z"
                    fill={color}/>
                <g>
                    <path
                        d="m103.98,50.46l-13.68-11.95c-.96-.84-2.31-.03-2.31,1.37v3.55h-12.72v-3.55c0-1.4-1.36-2.21-2.31-1.37l-13.68,11.95c-.78.68-.78,2.06,0,2.74l13.68,11.95c.96.84,2.31.03,2.31-1.37v-3.55h12.72v3.55c0,1.4,1.36,2.21,2.31,1.37l13.68-11.95c.78-.68.78-2.06,0-2.74Z"
                        fill={color}/>
                    <path
                        d="m73.82,66.49c-.54,0-1.07-.2-1.51-.59l-13.68-11.95c-.59-.51-.93-1.29-.93-2.12s.34-1.61.93-2.12l13.68-11.95c.71-.62,1.62-.76,2.45-.38.92.42,1.52,1.4,1.52,2.5v2.55h10.72v-2.55c0-1.1.6-2.08,1.52-2.5.83-.38,1.75-.24,2.45.38l13.68,11.95c.59.52.93,1.29.93,2.12,0,.83-.34,1.61-.93,2.12l-13.68,11.95c-.71.62-1.62.76-2.45.38-.92-.42-1.52-1.4-1.52-2.5v-2.55h-10.72v2.55c0,1.1-.6,2.09-1.52,2.5-.31.14-.62.21-.94.21Zm.46-7.26h14.72v4.55c0,.36.17.6.34.68.08.03.17.05.31-.07l13.68-11.95c.15-.13.24-.36.24-.62s-.09-.48-.24-.62l-13.68-11.95c-.14-.12-.23-.1-.31-.07-.17.08-.34.32-.34.68v4.55h-14.72v-4.55c0-.36-.17-.61-.34-.68-.08-.04-.17-.05-.31.07l-13.68,11.95c-.15.13-.24.36-.24.62s.09.48.24.62l13.68,11.95c.13.12.23.1.31.07.17-.08.34-.32.34-.68v-4.55Z"
                        fill={`#fff`}
                    />
                </g>
                <g>
                    <ellipse cx="128.6" cy="49.28" rx="16.71" ry="13.55" fill={color}/>
                    <path
                        d="m128.54,60.01c.67,4.13-1.64,7.92-1.64,7.92,0,0,9.5-2.73,13.38-9.68,1.28-2.29-.26-1.74-.26-1.74"
                        fill={color}/>
                </g>
            </g>
        </svg>
    )
}
