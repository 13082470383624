import React from 'react'

import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import SectionHeader from 'components/SectionHeader'

export default function Terms() {
  const { palette, spacing } = useTheme()

  const H3 = ({ children }) => (
    <Typography style={{ padding: spacing(3, 0) }} variant="h3">
      {children}
    </Typography>
  )

  const P = ({ children }) => (
    <Typography style={{ marginBottom: spacing(6) }} variant="body2">
      {children}
    </Typography>
  )

  return (
    <>
      <div style={{ background: palette.background.paper }}>
        <SectionHeader primary="オンラインランニングイベント規約" />
        <div style={{ padding: spacing(3) }}>
          <H3>１条 オンラインランニングイベントサービスについて</H3>
          <P>
            オンラインランニングイベントサービスとは、オンラインで参加可能なランニングイベント（以下「ランニングイベント」といいます。）をランニングイベントの主催者（以下「当オンラインイベント主催者」といいます。）が主催し、会員（以下「当オンラインイベント参加者」といいます。）がそれに参加することができるサービスです。
            <br />
            なお、当社が当オンラインイベント主催者となる場合もありますが、当社以外の第三者が当オンラインイベント主催者となる場合もあります。
            <br />
            会員は、ランニングイベントに参加する場合には、本規約及び当オンラインイベント主催者が別途定める遵守事項・ルールを遵守するものとします。
          </P>

          <H3>2条 オンラインランニングイベントサービスの利用</H3>
          <P>
            １項
            当オンラインイベント参加者は、当社所定の申込み方法によって、ランニングイベントへの参加を申し込むものとし、当該申込みを当オンラインイベント主催者が承諾する旨を通知した時点で、当オンラインイベント参加者と当オンラインイベント主催者との間にランニングイベントの参加に関する契約（以下「ランニングイベント参加契約」といいます。）が成立するものとします。
          </P>
          <P>
            ２項
            当オンラインイベント参加者は、ランニングイベントに参加する際に参加料の支払いが必要とされている場合には、当社に対して、当社所定の支払期日までに、ランニングイベントの参加料（以下「ランニングイベント参加料」といいます。）を支払うものとします。なお、当社以外の第三者が当オンラインイベント主催者となる場合には、当社がランニングイベント参加料を代理受領するものとし、当オンラインイベント参加者から当社への支払いによって、当オンラインイベント参加者の当オンラインイベント主催者に対するランニングイベント参加料の支払債務は消滅するものとします。
          </P>
          <P>
            ３項
            当オンラインイベント参加者は、ランニングイベントの当日に、当社所定のアプリケーションやデバイスを利用して、ランニングを行い、ランニングの結果を当社所定の方法により当社に提供します。
          </P>
          <P>
            ４項
            当社又は当オンラインイベント主催者は、当オンラインイベント参加者に対して、ランニングイベント終了後において、当社又は当オンラインイベント主催者が別途定める特定の条件を満たした場合に、当社又は当オンラインイベント主催者が別途定める賞品等（完走証及び特定のポイントなどを含みます。）を提供することがあります。なお、当オンラインイベント参加者が乗り物を使用している場合その他不正の方法によりランニングイベントに参加していると当社が判断した場合には、当社は、賞品等の提供の中止や賞品の没収など適切な措置をとることができます。また、当オンラインイベント参加者は、当社が当オンラインイベント参加者の正確な走行結果を取得することができない場合（GPSを取得できない場合、データに不具合がある場合、デバイスの電源が切れている場合、その他理由を問わないものとします。）には、当該賞品等の提供を受けとることができないことをあらかじめ了承するものとします。
          </P>
          <P>
            ５項
            当オンラインイベント参加者は、ランニングイベントの申込み後のキャンセルをすることはできず、また、ランニングイベント参加料の過剰入金・重複入金があった場合に当社及び当オンラインイベント主催者が返金する義務を負わないことをあらかじめ了承するものとします。
          </P>
          <P>
            ６項
            当社及び当オンラインイベント主催者は、当オンラインイベント参加者がランニングイベント参加料を支払期日までに入金をしない場合には、催告なく、ランニングイベント参加契約を解除することができます。なお、当オンラインイベント参加者は、ランニングイベント参加料の支払手続が完了していても、当社への入金日が支払期日後となる場合には、ランニングイベント参加契約が解除される場合があることをあらかじめ了承します。
          </P>

          <H3>3条 遵守事項</H3>
          <P>
            １項
            当オンラインイベント参加者は、ランニングイベント参加中に公共道路上にて走行等する際の危険性を認識の上、参加するものとします。また、当オンラインイベント参加者は、走行等にあたり、すべての交通法規を守り、信号機、標識等に従って安全に走行等するものとします。
          </P>
          <P>
            ２項
            当オンラインイベント参加者は、一般の車両や歩行者が関わる事故が発生した場合には、必ず警察、状況によっては消防に連絡するものとします。なお、適切な救命処置及び救急処置をとった後、可能な限りすみやかに当オンラインイベント主催者又は運営責任者の緊急電話番号に連絡するものとします。
          </P>
          <P>
            ３項
            当オンラインイベント参加者は、健康管理に細心の注意を払い、突発的な体調不良が発生した場合は自己の判断で参加を中止するものとし、当社及び当オンラインイベント主催者は、当オンラインイベント参加者の体調について一切責任を負わないものとします。
          </P>
          <P>
            ４項
            当オンラインイベント参加者は、ランニングイベントの安全開催・円滑な運営に努め、当社・当オンラインイベント主催者・運営責任者・会場提供者・スタッフ・協賛企業関係者（以下「当オンラインイベント主催者側」といいます。）の指示に従うものとします。
          </P>
          <P>
            ５項
            当オンラインイベント参加者及びその親族、友人、関係者は、当オンラインイベント主催者側並びに他の当オンラインイベント参加者の誹謗中傷を行わないものとします。
          </P>
          <P>
            ６項
            当オンラインイベント参加者は、ランニングイベント参加中、人が多い場所や危険を伴う場所を走行する場合には、歩行して通行するものとします。
          </P>
          <P>
            ７項
            当オンラインイベント参加者は、各自で疾病、感染症等の感染拡大防止のための取り組みを最大限行い、ランニングイベントに参加するものとします。
          </P>

          <H3>４条 責任</H3>
          <P>
            １項
            当社及び当オンラインイベント主催者は、地震・台風・降雪・事件・疾病等の当オンラインイベント主催者または運営責任者の責によらない事由で、ランニングイベントの開催が短縮・縮小・中止となった場合及びランニングイベントの内容が変更となった場合、ランニングイベント参加料及び交通費等の参加に要した費用の返金・補償等は一切行いません。
          </P>
          <P>
             ２項
            当社及び当オンラインイベント主催者は、当オンラインイベント参加者の傷病や事故、所有物の紛失等に関し一切の責任を負いません。
          </P>
          <P>
            ３項
            当オンラインイベント参加者及びその親族等は、ランニングイベントの参加中における傷病や事故、所有物の紛失等に関し、当社及び当オンラインイベント主催者の責任を免除し、損害賠償等の請求を行わないものとし、自己の責任において一切の処理をするものとします。 
          </P>

          <H3>５条 情報の利用</H3>
          <P>
            １項
            当オンラインイベント参加者は、当オンラインイベント主催者側が、ランニングイベント中の映像・写真・記事・当オンラインイベント参加者の氏名・肖像等をテレビ・新聞・雑誌・インターネットその他のメディアに掲載できることをあらかじめ了承するものとします。
          </P>
          <P>
            ２項
            当社は、当オンラインイベント主催者側に対して、当オンラインイベント参加者の情報を提供することができ、当オンラインイベント参加者は、当該情報の提供を承諾します。 
          </P>

          <P>
            以上
            <br />
            施行日 令和2年9月20日
          </P>
        </div>
      </div>
    </>
  )
}
