import React from 'react'

import { useTheme } from '@material-ui/core/styles'
import SectionHeader from './SectionHeader'

export default function SupportList() {
  const { spacing, palette } = useTheme()

  return (
    <div style={{ background: palette.background.paper }}>
      {process.env.REACT_APP_ACTIVITY_UPLOAD_TYPE === 'multiple' ?
        <div>
          <SectionHeader primary='対応アプリ / デバイス' />
          <div style={{ padding: spacing(6) }}>
            <li>Runtripアプリ</li>
            <li>GARMIN</li>
            <li>Polar</li>
            <li>Suunto</li>
            <li>Apple Watch</li>
            <li>adidas Running</li>
          </div>
        </div>
      : <div>
          <SectionHeader primary='対応アプリ / デバイス' />
          <div style={{ padding: spacing(6) }}>
            <li>Runtripアプリ</li>
            <li>GARMIN</li>
            <li>Polar</li>
            <li>Fitbit</li>
            <li>Suunto</li>
            <li>Apple Watch</li>
            <li>adidas Running</li>
          </div>
        </div>
      }
    </div>
  )
}
