import React from 'react'

import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import MultilineText from 'components/MultilineText'
import SectionHeader from 'components/SectionHeader'

export default function Concept() {
  const { spacing, palette } = useTheme()

  return (
    <div style={{ background: palette.background.paper }}>
      <SectionHeader primary="CONCEPT" />
      <div
        style={{
          margin: spacing(0, 3),
          padding: spacing(3),
        }}
      >
        {Boolean(process.env.REACT_APP_CONCEPT_MOVIE) && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: spacing(6),
          }}
        >
          <iframe width="560" height="315" src={process.env.REACT_APP_CONCEPT_MOVIE} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        )}

        {Boolean(process.env.REACT_APP_CONCEPT_TITLE) && (
          <Typography
            variant="body1"
            align="center"
            style={{ fontWeight: 'bold', marginBottom: spacing(6) }}
          >
            <MultilineText>{process.env.REACT_APP_CONCEPT_TITLE}</MultilineText>
          </Typography>
        )}
        <Typography variant="body2">
          <MultilineText>{process.env.REACT_APP_CONCEPT_DESCRIPTION}</MultilineText>
        </Typography>
      </div>
    </div>
  )
}
