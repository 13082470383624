import React, { useState } from 'react'
import { Controller } from 'react-hook-form'

import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

export default function CustomCheckbox({
  id,
  control,
  required,
  label,
  options,
  error,
  helperText,
}) {
  const { spacing } = useTheme()
  const [selected, setSelected] = useState(() => {
    let _selected = {}
    options.forEach(({ value }) => (_selected[value] = false))
    return _selected
  })

  return (
    <div style={{ padding: spacing(2, 0) }}>
      <FormControl>
        <Typography variant="caption" color={error === undefined ? 'textSecondary' : 'error'}>
          {label}
        </Typography>
        <Controller
          as={
            <FormGroup>
              {options.map(({ label, value }) => (
                <FormControlLabel key={value} control={<Checkbox name={value} />} label={label} />
              ))}
            </FormGroup>
          }
          onChange={([e]) => {
            const _selected = { ...selected, [e.target.name]: e.target.checked }
            setSelected(_selected)
            return Object.entries(_selected)
              .filter(([value, bool]) => bool)
              .map(([value]) => value)
              .join(',')
          }}
          defaultValue=""
          control={control}
          name={id}
          rules={{ required }}
        />
        {Boolean(helperText) && <FormHelperText>{helperText}</FormHelperText>}
        {error && <FormHelperText error>必須項目です</FormHelperText>}
      </FormControl>
    </div>
  )
}
