import React from 'react'
import WarningRoundedIcon from '@material-ui/icons/Info';

import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import MultilineText from 'components/MultilineText'

export default function Information({ text }) {
  const { spacing, palette } = useTheme()

  return (
    <div style={{ background: palette.background.paper }}>
      <div
        style={{
          margin: spacing(3, 3),
          padding: spacing(3),
          background: "#E6FAFF",
          border: "1px solid #42B8E4",
          borderRadius: "8px",
        }}
      >
        <Typography variant="body2">
          <div
              style={{
                  display: 'flex',
                  alignItems: 'center',
              }}
          >
            <div>
              <WarningRoundedIcon
                style={{
                  color: '#42B8E4',
                  marginRight: spacing(3),
                }}
              />
            </div>
            <div>
              <MultilineText>{text}</MultilineText>
            </div>
          </div>
    </Typography>
  </div>
</div>
)
}
