import { useCallback } from 'react'

const sessionStorage = window.sessionStorage
const keys = {
  TRACKER: 'TRACKER',
  EVENT: 'EVENT',
  GARMIN_SECRET: 'GARMIN_SECRET',
  TWITTER_SECRET: 'TWITTER_SECRET',
}

export default function useSessionStorage() {
  const getItem = useCallback((key, options = { remove: false }) => {
    const item = sessionStorage.getItem(key)
    if (!item) return null
    if (options.remove) {
      sessionStorage.removeItem(key)
    }
    return item
  }, [])

  const setItem = useCallback((key, value) => {
    sessionStorage.setItem(key, value)
  }, [])

  return { setItem, getItem, keys }
}
