import React from 'react'

import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import Markdown from 'components/Markdown'

export default function EventInfoListItem({ label, value, markdown }) {
  const { spacing, palette } = useTheme()

  return (
    <div style={{ padding: spacing(3, 0), borderBottom: `1px solid ${palette.divider}` }}>
      <Typography variant="caption">{label}</Typography>
      {markdown ? <Markdown>{value}</Markdown> : <Typography variant="body2">{value}</Typography>}
    </div>
  )
}
