import React, { Children } from 'react'
import ReactMarkdown from 'react-markdown'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ palette }) => ({
  markdown: {
    color: palette.text.secondary,
    '& img': {
      maxWidth: '100%',
      display: 'block',
      margin: 'auto',
    },
    '& h2': {
      position: 'relative',
      color: palette.text.primary,
      lineHeight: 1.4,
      paddingBottom: 8,
      marginTop: 12,
      marginBottom: 12,
      fontSize: 20,
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        bottom: -1,
        width: 120,
        height: 1,
        background: `${process.env.REACT_APP_COLOR_PRIMARY}`,
      },
    },
    '& h3': {
      color: palette.text.primary,
      lineHeight: 1.4,
      marginTop: 12,
      marginBottom: 12,
      fontSize: 18,
    },
    '& p': {
      marginBottom: 12,
    },
    '& a': {
      textDecoration: 'underline',
    },
    '& ul, ol': {
      paddingLeft: 28,
    },
    '& li > ol, li > ul': {
      paddingLeft: 12,
    },
    '& li': {
      marginBottom: 8,
    },
  },
}))

export default function Markdown({ children, ...props }) {
  const classes = useStyles()
  return (
    <ReactMarkdown
      className={classes.markdown}
      linkTarget="_blank"
      components={{
        p: ({ children, ...props }) => (
          <p {...props}>
            {Children.map(children, value => {
              if (value === '<br />') {
                return <div style={{ height: 24 }} />
              }
              return value
            })}
          </p>
        ),
      }}
      {...props}
    >
      {children}
    </ReactMarkdown>
  )
}
