import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import ActivityListItem from 'components/ActivityListItem'
import { ReactComponent as CloseIcon } from 'images/icons/close.svg'

export default function ActivityListPopup({ tracker, activities, onSelect, onClose }) {
  const { spacing } = useTheme()

  return (
    <Dialog open={true} onClose={onClose}>
      <IconButton
        onClick={onClose}
        style={{ position: 'absolute', top: spacing(1), right: spacing(1) }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle style={{ textAlign: 'center', padding: spacing(4, 10) }} disableTypography>
        <Typography variant="h3">
          {tracker.name}
          <br />
          アクティビティデータ
        </Typography>
      </DialogTitle>
      <DialogContent style={{ padding: spacing(4, 0) }}>
        {activities.map(x => (
          <ActivityListItem key={x.id} activity={x} onClick={() => onSelect(x)} />
        ))}
      </DialogContent>
    </Dialog>
  )
}
