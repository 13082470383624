import React from 'react'

import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Carousel from 'components/Carousel'
import SectionHeader from 'components/SectionHeader'
import EntryIcon from 'icons/EntryIcon'
import PostIcon from 'icons/PostIcon'
import LineLink from 'icons/LineLink'
import Beer from "icons/Beer";
import HighFive from "icons/HighFive";
import RunOrWalkIcon from 'icons/RunOrWalkIcon'
import UploadIcon from 'icons/UploadIcon'
import RunIcon from "../icons/RunIcon";
import FinisherIcon from "../icons/FinisherIcon";

const items = [
  {
    title: Boolean(process.env.REACT_APP_HOW_IT_WORKS_ENTRY_TITLE)
        ? process.env.REACT_APP_HOW_IT_WORKS_ENTRY_TITLE
        : 'ENTRY',
    description: Boolean(process.env.REACT_APP_HOW_IT_WORKS_ENTRY_TEXT)
      ? process.env.REACT_APP_HOW_IT_WORKS_ENTRY_TEXT
      : 'まずはエントリー。完走できる目標距離を選んだら、アプリを事前接続しましょう。',
    Icon: (()=> {
        return EntryIcon
    })()
  },
  {
    title: Boolean(process.env.REACT_APP_HOW_IT_WORKS_RUN_OR_WALK_TITLE)
        ? process.env.REACT_APP_HOW_IT_WORKS_RUN_OR_WALK_TITLE
        : 'RUN or WALK',
    description: Boolean(process.env.REACT_APP_HOW_IT_WORKS_RUN_OR_WALK_TEXT)
      ? process.env.REACT_APP_HOW_IT_WORKS_RUN_OR_WALK_TEXT
      : '好きな場所をあなたのペースで走り（歩き）ましょう！アプリやデバイスの計測を忘れずに。',
    Icon: (()=> {
        if(process.env.REACT_APP_HOW_IT_WORKS_RUN_OR_WALK_ICON === 'Run') {
            return RunIcon
        } else {
            return RunOrWalkIcon
        }
    })()
  },
  {
    title: Boolean(process.env.REACT_APP_HOW_IT_WORKS_UPLOAD_TITLE)
        ? process.env.REACT_APP_HOW_IT_WORKS_UPLOAD_TITLE
        : 'UPLOAD',
    description: Boolean(process.env.REACT_APP_HOW_IT_WORKS_UPLOAD_TEXT)
      ? process.env.REACT_APP_HOW_IT_WORKS_UPLOAD_TEXT
      : '完走（完歩）したら、マイページに記録をアップロード。デジタル完走証が発行されます。',
    Icon: (()=> {
        if(process.env.REACT_APP_HOW_IT_WORKS_UPLOAD_ICON === 'LineLink') {
            return LineLink
        } else if(process.env.REACT_APP_HOW_IT_WORKS_UPLOAD_ICON === 'Finisher') {
            return FinisherIcon
        } else {
            return UploadIcon
        }
    })()
  },
  {
    title: Boolean(process.env.REACT_APP_HOW_IT_WORKS_POST_TITLE)
        ? process.env.REACT_APP_HOW_IT_WORKS_POST_TITLE
        : 'POST',
    description: Boolean(process.env.REACT_APP_HOW_IT_WORKS_POST_TEXT)
      ? process.env.REACT_APP_HOW_IT_WORKS_POST_TEXT
      : 'Runtripアプリにイベントの様子を投稿。豪華賞品が当たるかも！ハッシュタグも忘れずに。',
    Icon: (()=> {
      if(process.env.REACT_APP_HOW_IT_WORKS_POST_ICON === 'Beer') {
          return Beer
      } else if (process.env.REACT_APP_HOW_IT_WORKS_POST_ICON === 'HighFive') {
          return HighFive
      } else {
        return PostIcon
      }
    })()
  },
]

export default function HowItWorks() {
  const xs = useMediaQuery(({ breakpoints }) => breakpoints.down('xs'))
  const { palette, spacing } = useTheme()

  const Item = ({ title, description, Icon, style }) => (
    <div
      style={{
        border: `1px solid ${palette.primary.main}`,
        borderRadius: 4,
        width: 174,
        padding: spacing(2, 3),
        ...style,
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <Icon color={palette.primary.main} />
        <Typography
          variant="h3"
          style={{ margin: spacing(2, 0), color: palette.primary.main, fontSize: 16 }}
        >
          {title}
        </Typography>
      </div>
      <Typography variant="body1" style={{ color: palette.primary.main, fontSize: 14 }}>
        {description}
      </Typography>
    </div>
  )

  return (
    <div
      style={{
        background: palette.background.paper,
        marginBottom: spacing(6),
        paddingBottom: spacing(6),
      }}
    >
      <SectionHeader primary="HOW IT WORKS" />
      {xs ? (
        <Carousel>
          {items.map((props, i) => (
            <Item key={i} {...props} style={{ minWidth: 174 }} />
          ))}
        </Carousel>
      ) : (
        <div
          style={{
            padding: spacing(0, 6),
            display: 'flex',
            gap: spacing(3),
          }}
        >
          {items.map((props, i) => (
            <Item key={i} {...props} />
          ))}
        </div>
      )}
    </div>
  )
}
