import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export default function useQuery() {
  const { search } = useLocation()

  const query = useMemo(() => {
    if (search === '') return {}

    return search
      .replace('?', '')
      .split('&')
      .map(elem => {
        const [key, value] = elem.split('=')
        let obj = {}
        obj[key] = decodeURIComponent(value)
        return obj
      })
      .reduce((a, b) => ({ ...(a || {}), ...(b || {}) }))
  }, [search])

  return query
}
