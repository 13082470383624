import React from 'react'
import { Link } from 'react-router-dom'

import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'

export default function NewsListItem({ item }) {
  const { spacing, palette } = useTheme()

  return (
    <div
      style={{
        margin: spacing(0, 3),
        padding: spacing(3),
        borderBottom: `1px solid ${palette.divider}`,
      }}
    >
      <div style={{ display: 'flex' }}>
        {item.isNew && (
          <Typography
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 12,
              width: 60,
              height: 20,
              background: process.env.REACT_APP_COLOR_NEWS_LABEL,
              marginRight: spacing(2),
            }}
            variant="body1"
          >
            NEW
          </Typography>
        )}
        <Typography variant="caption" style={{ display: 'block', marginBottom: spacing(2) }}>
          {moment(item.publication_date).format('YYYY.MM.DD')}
        </Typography>
      </div>
      <Link to={`/news/${item.id}`}>
        <Typography style={{ color: palette.link }} variant="body2">
          {item.title}
        </Typography>
      </Link>
    </div>
  )
}
