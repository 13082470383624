import React from 'react'

import FormHelperText from '@material-ui/core/FormHelperText'
import { useTheme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

export default function CustomTextField({
  id,
  type,
  label,
  register,
  placeholder,
  style,
  error,
  helperText,
}) {
  const { spacing } = useTheme()
  return (
    <div style={{ padding: spacing(2, 0), ...style }}>
      <Typography variant="caption" color={Boolean(error) ? 'error' : 'textSecondary'}>
        {label}
      </Typography>
      <TextField
        id={id}
        color="secondary"
        name={id}
        inputRef={register}
        type={type || 'string'}
        required
        fullWidth
        placeholder={placeholder}
        InputLabelProps={{ shrink: false }}
        error={Boolean(error)}
      />
      {Boolean(helperText) && <FormHelperText>{helperText}</FormHelperText>}
      {Boolean(error) && <FormHelperText error>{error.message}</FormHelperText>}
    </div>
  )
}
