import React from 'react'

export default function RunIcon({ color }) {
  return (
    <svg width='95' height='60' viewBox="0 0 113.24 152.99" xmlns="http://www.w3.org/2000/svg" >
      <circle cx="68.11" cy="14.2" r="14.2" fill={color}/>
      <path d="M40.22,89.65c-.67,3.11-2.48,11.6-2.93,13.42-.56,2.24-.56,3-1.87,4.3,0,0-22.14,8.26-30.9,11.72C-4.69,122.74,2.08,134.35,8,133c4.68-1.09,34.63-7.93,38.55-10a14.83,14.83,0,0,0,7-8.42c.58-1.72,3.19-7.16,4.25-10.46-2.84-2.23-6.75-5.3-8.63-6.74S43.49,92.58,40.22,89.65Z" fill={color}/>
      <path d="M86.05,69.63c2.43,1.87,6.35.56,9.34-2.24S109,55.43,111.27,53s-2.8-12.15-6.54-9.34S92.4,52.44,91.28,53s-1.68-1.31-3.17-2.43c-.8-.6-4.37-4.33-7.85-7.91A117.1,117.1,0,0,1,77.63,62.1C80.57,64.82,84.58,68.49,86.05,69.63Z" fill={color}/>
      <path d="M112.71,144.11c-3.1-7.13-15.45-36.55-17.13-39.73S94.27,99,87.73,94.85,68.11,78.22,68.11,78.22l6.06-18.89a132.22,132.22,0,0,0,2.25-20.55,31,31,0,0,0-3.2-2.91c-2.24-1.49-3-2.8-8.22-3.73s-7.72-1.56-10.34-1.75S47.94,31.51,42.89,33s-17.69,8-22,9.84-5.55,8.53-1.44,13.76c3.71,4.72,15.15,20.81,18.47,25.58l1.3,1.19c1.82,1.32,5.22,2.77,9.21-.46,3.09-2.48,1.47-7.72.76-8.9C48.07,72.13,40.33,57.55,40,56.93a2,2,0,0,1,.74-2.66l8.41-5a2,2,0,0,1,2.06,3.43l-6.78,4.07C47.34,62.2,51.86,70.66,52.64,72,54,74.25,56.17,81.85,51,86a12.38,12.38,0,0,1-5.74,2.72c2.56,2.27,5.09,4.48,6.35,5.44,3.15,2.41,11.94,9.33,12,9.4l.06.06c4.39,3,10.45,7.22,11.73,8.38a23.14,23.14,0,0,1,4.67,6.16C81.75,121,97,146.3,99.32,150,103.49,156.44,115.94,151.59,112.71,144.11Z" fill={color}/>
    </svg>
  )
}
