import React, { useCallback, useEffect } from 'react'

import Button from '@material-ui/core/Button'

import { useAuth } from 'hooks/useAuth'

const config = {
  scope:
    'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
  discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
  clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
}

export default function GoogleLogin({ onError }) {
  const { signInWithSns } = useAuth()

  useEffect(() => {
    window.gapi.load('client:auth2', () => {
      window.gapi.client.init(config)
    })
  }, [])

  const signIn = useCallback(async () => {
    const user = await window.gapi.auth2.getAuthInstance().signIn()
    const { expires_at, access_token } = user.getAuthResponse()
    signInWithSns({
      provider: 'Google',
      provider_user_id: user.getId(),
      access_token,
      access_token_expires: expires_at,
      access_token_secret: ' ',
    }).catch(onError)
  }, [signInWithSns, onError])

  return (
    <Button style={{ padding: 0, marginBottom: 12 }} onClick={signIn}>
      <img
        src={require('images/sns/google.png')}
        alt="Sign in with Google"
        style={{ width: 210, height: 40 }}
      />
    </Button>
  )
}
