import React from 'react'

export default function HighFive({color}) {
    return (
        <svg height='60' viewBox="0 0 166.89 133.05" fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g>
                <path d="m87.93,14.58c-.16,3.91-.33,7.82-.5,11.72-.01.25-.04.49-.08.73-.29,1.63-1.72,2.71-3.43,2.58-1.52-.11-2.78-1.42-2.81-3-.02-1.08.05-2.16.1-3.24.28-6.54.56-13.08.84-19.61.01-.27.03-.54.06-.81.17-1.74,1.6-3.02,3.28-2.94,1.7.08,3.02,1.46,2.99,3.22-.03,2.02-.15,4.03-.23,6.05-.07,1.77-.16,3.54-.23,5.31Z"
                      fill={color}/>
                <path d="m100.71,30.31c-2.24-.13-3.62-2.34-2.79-4.32.62-1.47,1.33-2.91,2-4.36,1.84-3.95,3.69-7.89,5.51-11.84.44-.95,1.11-1.64,2.12-1.91,1.25-.34,2.36,0,3.2.97.87,1,1.04,2.16.49,3.38-.83,1.84-1.69,3.66-2.54,5.49-1.63,3.5-3.26,7-4.88,10.51-.39.84-.94,1.49-1.81,1.85-.46.19-.95.26-1.3.23Z"
                      fill={color}/>
                <path d="m68.16,30.21c-1.45.23-2.73-.44-3.45-1.84-1.1-2.14-2.18-4.3-3.27-6.44-1.35-2.65-2.69-5.3-4.04-7.95-.28-.56-.49-1.13-.49-1.76.02-1.4.94-2.6,2.3-3.02,1.27-.39,2.73.15,3.5,1.29.14.2.25.42.36.64,2.41,4.76,4.82,9.52,7.23,14.27.31.6.51,1.22.46,1.91-.1,1.45-1.2,2.68-2.61,2.9Z"
                      fill={color}/>
            </g>
            <g>
                <path d="m31.66,83.42c10.56,0,19.12-8.56,19.12-19.12s-8.56-19.12-19.12-19.12-19.12,8.56-19.12,19.12,8.56,19.12,19.12,19.12Z"
                      fill={color}/>
                <path d="m68.85,36.92c-4.58-.24-8.36,3.4-8.36,7.93v4.87c.29,17.04-3.4,29.24-10.66,35.27-.95.79-1.9,1.52-2.85,2.21-4.39,2.94-9.65,4.68-15.32,4.68-6.61,0-12.68-2.34-17.44-6.23-7.93,3.62-14.22,10.29-14.22,21.47v21.95c0,2.21,1.79,3.99,3.99,3.99h55.34c2.21,0,3.99-1.79,3.99-3.99v-21.95c0-.7-.02-1.38-.07-2.05-.41-5.66.83-11.29,3.98-16.01,6.35-9.51,9.42-22.74,9.13-39.47v-4.29c0-4.3-3.22-8.15-7.51-8.37Z"
                      fill={color}/>
                <path d="m135.23,83.42c10.56,0,19.12-8.56,19.12-19.12s-8.56-19.12-19.12-19.12-19.12,8.56-19.12,19.12,8.56,19.12,19.12,19.12Z"
                      fill={color}/>
                <path d="m152.67,85.65c-4.76,3.89-10.83,6.23-17.44,6.23-5.67,0-10.94-1.73-15.32-4.68-.95-.69-1.9-1.43-2.85-2.21-7.26-6.04-10.95-18.23-10.66-35.27v-4.87c0-4.53-3.78-8.16-8.36-7.93-4.29.23-7.51,4.08-7.51,8.37v4.29c-.29,16.73,2.78,29.97,9.13,39.47,3.15,4.73,4.39,10.35,3.98,16.01-.05.67-.07,1.35-.07,2.05v21.95c0,2.21,1.79,3.99,3.99,3.99h55.34c2.2,0,3.99-1.79,3.99-3.99v-21.95c0-11.18-6.29-17.85-14.22-21.47Z"
                      fill={color}/>
            </g>
        </svg>
    )
}
