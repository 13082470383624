import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import ButtonBase from '@material-ui/core/ButtonBase'
import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Logout from 'components/Logout'

export default function Footer() {
  const { palette, spacing } = useTheme()
  const { pathname } = useLocation()
  const xs = useMediaQuery(({ breakpoints }) => breakpoints.down('xs'))

  const Divider = () => (
    <div
      style={{
        margin: spacing(0, 3),
        width: 1,
        height: 12,
        background: palette.divider,
      }}
    />
  )

  const SponcerList = ({ sponcers, label }) => {
    const imageSize = xs ? { width: 120, height: 70 } : { width: 150, height: 100 }
    return (
      <div style={{ paddingTop: spacing(6), margin: '0 auto', width: xs ? 256 : 364 }}>
        {JSON.parse(process.env.REACT_APP_SPONCERS).map(({ label, sponcers }) => (
          <div
            key={label}
            style={{
              display: 'flex',
              flexDirection: xs ? 'column' : 'row',
              marginBottom: spacing(1),
              alignItems: xs ? 'center' : 'start',
            }}
          >
            <Typography
              style={{
                paddingTop: xs ? 0 : 50 - Math.ceil(label.length / 2) * 10,
                marginRight: xs ? 0 : spacing(6),
                minWidth: 24,
                maxWidth: xs ? 256 : 24,
              }}
              variant="caption"
              component="p"
              align="right"
            >
              {label}
            </Typography>
            <div>
              {sponcers.map((sponcer, i) => (
                <img
                  key={sponcer}
                  style={{
                    ...imageSize,
                    objectFit: 'contain',
                    marginBottom: spacing(4),
                    marginRight: sponcers.length > 1 && i % 2 === 0 ? spacing(4) : 0,
                  }}
                  src={`/images/sponcer/${sponcer}.png`}
                  alt={label}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div
      style={{
        padding: spacing(6, xs ? 0 : 3),
        width: '100%',
        maxWidth: 800,
        margin: '0 auto',
      }}
    >
      {pathname !== '/users/me' && (
        <div style={{ textAlign: 'center' }}>
          <ButtonBase
            style={{ marginBottom: spacing(6) }}
            onClick={() => window.open(process.env.REACT_APP_FOR_BEGINNER_URL)}
          >
            <img
              style={{ height: 76 }}
              src={`/images/banner/beginner_${xs ? 'sp' : 'desktop'}.png`}
              alt="はじめてガイド"
            />
          </ButtonBase>
        </div>
      )}
      <div style={{ width: '100%', height: 1, background: palette.divider }} />
      <SponcerList />
      <Logout />
      <div style={{ width: '100%', height: 1, background: palette.divider }} />
      <div
        style={{
          padding: spacing(6, 0, 3),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          style={{ color: palette.link, cursor: 'pointer' }}
          variant="caption"
          onClick={() => window.open(process.env.REACT_APP_FAQ_URL)}
        >
          よくある質問
        </Typography>
        <Divider />
        <Link to="/terms">
          <Typography style={{ color: palette.link }} variant="caption">
            利用規約
          </Typography>
        </Link>
        <Divider />
        <Link to="/privacypolicy">
          <Typography style={{ color: palette.link }} variant="caption">
            プライバシーポリシー
          </Typography>
        </Link>
      </div>
      <Typography
        style={{ marginBottom: spacing(3), color: palette.link, cursor: 'pointer' }}
        align="center"
        variant="caption"
        component="p"
        onClick={() => window.open(process.env.REACT_APP_SPECIFIED_COMMERCIAL_TRANSACTIONS_URL)}
      >
        特定商取引法に基づく表示
      </Typography>
      <Typography
        style={{ marginBottom: spacing(3), color: palette.link, cursor: 'pointer' }}
        align="center"
        variant="caption"
        component="p"
        onClick={() => window.open(process.env.REACT_APP_INQUIRY_PAYMENT_URL)}
      >
        決済に関するお問い合わせ
      </Typography>
      <Typography align="center" variant="caption" component="p">
        Copyright (C) Runtrip, Inc. All Rights Reserved.
      </Typography>
    </div>
  )
}
