import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'

import moment from 'moment'

import { useAPI } from 'hooks/useAPI'

const EventContext = createContext()

export const EventState = Object.freeze({
  BEFORE: 0,
  OPENING: 1,
  UPLOAD_OPENING: 2,
  FINISHED: 3,
})

export const EntryState = Object.freeze({
  BEFORE: 0,
  OPENING: 1,
  CLOSED: 2,
})

export const DistanceUnit = Object.freeze({
  METER: 1,
  MILE: 2,
})

export const ParticipantStatus = Object.freeze({
  UNREGISTERED: 0,
  REGISTERED: 1,
  CANCELED: 2,
})

export const Competition = Object.freeze({
  RUN: 1,
  WALK: 2,
})

export const OptionalFormType = Object.freeze({
  Text: 'text',
  Checkbox: 'checkbox',
  Radio: 'radio',
  Select: 'select',
})

const getEventState = (event, now) => {
  // イベント終了日 + 7日後以降はアップロード不可
  if (moment(event.end_time).add(6, 'days').endOf('day').diff(now) < 0) {
    return EventState.FINISHED
    // イベント終了日時を過ぎた場合はアップロード可能
  } else if (moment(event.end_time).endOf('day').diff(now) < 0) {
    return EventState.UPLOAD_OPENING
    // イベント開始日時を過ぎた場合は開催中
  } else if (moment(event.start_time).diff(now) < 0) {
    return EventState.OPENING
    // それ以外のパターンはイベント開始前
  } else {
    return EventState.BEFORE
  }
}

const getEntryState = (event, now) => {
  // エントリー終了日時を過ぎた場合はエントリー不可能
  if (moment(event.entry_end_datetime).diff(now) < 0) {
    return EntryState.CLOSED
    // エントリー開始日時を過ぎた場合はエントリー可能
  } else if (moment(event.entry_start_datetime).diff(now) < 0) {
    return EntryState.OPENING
    // それ以外のパターンはイベント開始前
  } else {
    return EntryState.BEFORE
  }
}

const getPaymentMethod = event => {
  // 開催日の5日前まではコンビニ決済も可能
  if (moment(event.start_time).add(-5, 'days').endOf('day').diff(moment()) < 0) {
    return ['credit_card']
  } else {
    return ['credit_card', 'konbini']
  }
}

export function EventProvider({ children }) {
  const [eventResources, setEventResources] = useState([])
  const [participantResources, setParticipantResources] = useState([])
  const { client } = useAPI()

  const init = useCallback(eventResource => {
    const { event, activity } = eventResource
    event.state = getEventState(event, moment())
    event.entryState = getEntryState(event, moment())
    event.paymentMethod = getPaymentMethod(event)
    event.competitionText = event.competition === Competition.RUN ? 'ランニング' : 'ウォーキング'
    if (Boolean(event.optional_forms)) {
      event.optional_forms = event.optional_forms.map(field => {
        if (
          [OptionalFormType.Checkbox, OptionalFormType.Radio, OptionalFormType.Select].includes(
            field.type
          )
        ) {
          field.item = field.item.split(',').map(kv => {
            const [value, label] = kv.split(':')
            return { value, label }
          })
        }

        return field
      })
    }
    activity.uploaded = activity.id !== 0
    return { ...eventResource, event, activity }
  }, [])

  useEffect(() => {
    client
      .get('/vrwc/events', {
        params: {
          start_datetime: moment().format('YYYY-MM-DD 00:00:00'),
          end_datetime: moment().add(180, 'days').format('YYYY-MM-DD 23:59:59'),
        },
      })
      .then(res => setEventResources(res.data.events.map(x => init(x))))
  }, [client, init])

  const getParticipants = useCallback(() => {
    client.get('/vrwc/event_participant/me').then(res => {
      setParticipantResources(res.data.participants.map(x => init(x)))
    })
  }, [client, init])

  const getEvent = useCallback(
    id => {
      return client.get(`/vrwc/events/${id}`).then(res => init(res.data))
    },
    [client, init]
  )

  return (
    <EventContext.Provider
      value={{ eventResources, participantResources, getEvent, getParticipants, initEvent: init }}
      children={children}
    />
  )
}

export function useEvent() {
  return useContext(EventContext)
}
