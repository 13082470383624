import React from 'react'

export default function RunOrWalkIcon({ color }) {
  return (
    <svg width="76" height="60" viewBox="0 0 76 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M48.7428 37.2175C47.9367 36.5639 47.3478 35.5862 46.9536 34.5162L45.3154 41.8111L34.8233 49.1194C33.5327 50.0149 33.1555 51.758 33.9603 53.1077C34.7651 54.4575 36.4768 54.9529 37.8771 54.2412L50.9325 47.6341C51.6974 47.2456 52.2799 46.5726 52.5551 45.7594L54.1156 41.1202C48.8256 37.2858 48.7749 37.2444 48.7428 37.2175Z"
        fill={color}
      />
      <path
        d="M62.3973 17.2772C62.6746 18.7873 62.5318 20.457 61.9575 22.1535L61.1865 24.3379L64.1875 27.1117C65.238 28.0741 66.8444 28.0893 67.9129 27.1469L74.3071 21.5041C75.2887 20.649 75.4334 19.1759 74.6369 18.1458C73.8404 17.1157 72.3793 16.8865 71.3061 17.6231L66.371 20.9541L62.3973 17.2772Z"
        fill={color}
      />
      <ellipse cx="57.5192" cy="6.28257" rx="5.29211" ry="5.29685" fill={color} />
      <path
        d="M74.8186 55.7575L67.9039 40.1435C67.7387 39.7727 67.5008 39.4388 67.2044 39.1616L57.6022 30.483L60.6943 21.7215C62.112 17.5319 60.3404 14.232 57.9065 13.5846C56.526 13.2169 53.4805 12.7736 52.4219 12.6234C52.3039 12.6027 50.9245 12.3127 48.7193 13.0243C48.7193 13.0243 39.8818 16.5241 39.083 17.0285C37.5483 17.999 37.2513 20.1358 38.3006 21.6138L45.9449 32.4198C46.704 33.4608 48.1306 33.7511 49.2355 33.0895C50.3405 32.428 50.7595 31.0325 50.2022 29.8708L46.1105 21.648L50.3967 19.9089L51.0228 21.1519L47.9473 22.343L51.3964 29.2753C51.9447 30.3751 51.9316 31.6717 51.3611 32.7602C50.7906 33.8487 49.7322 34.5964 48.5164 34.77C48.7702 35.3128 49.136 35.7957 49.5896 36.1869C49.6692 36.2521 61.2935 44.6739 61.2935 44.6739L69.4324 58.5302C70.2554 59.8983 71.997 60.3939 73.4161 59.6638C74.8351 58.9337 75.446 57.2278 74.8135 55.7616L74.8186 55.7575Z"
        fill={color}
      />
      <ellipse
        cx="17.8771"
        cy="5.50258"
        rx="5.11285"
        ry="5.08597"
        transform="rotate(-4.88 17.8771 5.50258)"
        fill={color}
      />
      <path
        d="M0.869539 26.9237C0.879897 26.832 1.14197 24.6477 1.57704 22.3191C2.47203 17.528 3.32973 16.1525 4.50751 15.3952C4.74472 15.2417 5.10521 15.0109 9.98208 13.3232C10.8967 13.0069 12.2216 12.5762 13.2969 12.2135C14.9304 11.6623 16.101 11.5026 16.9669 11.6643C17.923 11.8436 19.6146 12.3135 20.4951 13.7178C24.8996 20.7458 25.8226 22.1965 26.0515 22.6045C26.642 23.2815 29.301 24.9382 31.8296 26.1984C32.7526 26.6451 33.363 27.5509 33.4278 28.5699C33.4926 29.5889 33.0018 30.5639 32.1427 31.1228C31.2836 31.6817 30.1888 31.7382 29.2762 31.2707C29.2088 31.2367 27.6074 30.4382 25.8847 29.4161C22.4436 27.3688 21.4668 26.1695 21.0162 25.2752C20.9063 25.0887 20.6018 24.6003 20.1875 23.9357C20.1553 24.2448 20.1181 24.6013 20.0777 24.9929C19.886 26.833 19.4416 31.1193 19.4106 31.9178C19.4106 31.9466 19.4106 31.9755 19.4106 32.0043C20.8608 33.6529 23.2143 36.3122 24.708 37.9618C25.8692 39.2435 26.5415 41.8616 28.1264 51.3047C28.4236 53.0697 28.7044 54.7378 28.8691 55.4415C29.2341 57.0064 28.2546 58.5693 26.6813 58.9323C25.1081 59.2954 23.5368 58.3211 23.1718 56.7562C22.9646 55.8763 22.6984 54.2834 22.3586 52.264C21.8552 49.276 20.8235 43.1434 20.1895 41.6648C18.8553 40.1883 16.9504 38.038 15.553 36.4544C15.553 36.4544 15.553 36.4636 15.553 36.4678L10.5518 31.6818C10.6388 30.5577 10.9081 27.6934 11.2821 24.0903C11.3422 23.5081 11.395 23.0002 11.4333 22.62C11.5897 21.0889 11.7223 19.767 11.829 18.6975C10.3395 19.2126 8.83848 19.7474 7.96524 20.073C7.51153 21.365 6.88587 24.5848 6.52642 27.5996C6.3531 29.0188 5.14508 30.0879 3.70782 30.0941C3.59428 30.0942 3.48082 30.0877 3.36805 30.0745C2.61644 29.9868 1.93074 29.6054 1.46207 29.0143C0.993403 28.4233 0.780233 27.6712 0.869539 26.9237Z"
        fill={color}
      />
      <path
        d="M8.81541 41.4597C8.99462 40.5932 9.51462 37.7505 10.0585 34.168L15.1415 39.0951C14.8525 40.893 14.5821 42.4952 14.4609 42.9753C14.3573 43.3741 14.175 44.1159 11.3077 48.9833C9.98801 51.2222 8.30369 53.9691 7.51643 55.0623C6.56337 56.3381 4.75697 56.6163 3.46067 55.6868C2.16437 54.7574 1.85863 52.9648 2.77422 51.6621C3.99241 49.9806 8.20632 42.8558 8.81541 41.4597Z"
        fill={color}
      />
    </svg>
  )
}
