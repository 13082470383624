import React from 'react'
import { Controller } from 'react-hook-form'

import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import CustomCheckbox from 'components/CustomCheckbox'
import CustomSelect from 'components/CustomSelect'
import CustomTextField from 'components/CustomTextField'
import { OptionalFormType } from 'hooks/useEvent'

export default function OptionalForm({ register, control, id, field, error }) {
  const { spacing } = useTheme()

  switch (field.type) {
    case OptionalFormType.Text:
      return (
        <CustomTextField
          id={id}
          label={field.label}
          placeholder={field.item}
          register={register({ required: field.required, maxLength: 100 })}
          error={Boolean(error) && { message: '100文字以内で入力して下さい' }}
          helperText={field.attention}
        />
      )
    case OptionalFormType.Checkbox:
      return (
        <CustomCheckbox
          id={id}
          control={control}
          required={field.required}
          label={field.label}
          options={field.item}
          error={error}
          helperText={field.attention}
        />
      )
    case OptionalFormType.Radio:
      return (
        <div style={{ padding: spacing(2, 0) }}>
          <FormControl>
            <Typography variant="caption" color={error === undefined ? 'textSecondary' : 'error'}>
              {field.label}
            </Typography>
            <Controller
              as={
                <RadioGroup name="gender1" value="female">
                  {field.item.map(({ label, value }) => (
                    <FormControlLabel key={value} value={value} control={<Radio />} label={label} />
                  ))}
                </RadioGroup>
              }
              onChange={([e]) => e.target.value}
              defaultValue=""
              control={control}
              name={id}
              rules={{ required: field.required }}
            />
            {Boolean(field.attention) && <FormHelperText>{field.attention}</FormHelperText>}
            {error && <FormHelperText error>必須項目です</FormHelperText>}
          </FormControl>
        </div>
      )
    case OptionalFormType.Select:
      return (
        <CustomSelect
          id={id}
          label={field.label}
          control={control}
          rules={{ required: field.required }}
          options={field.item}
          helperText={field.attention}
          error={error}
        />
      )
    default:
      return null
  }
}
