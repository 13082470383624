import React from 'react'

export default function UploadIcon({ color }) {
  return (
    <svg width="78" height="60" viewBox="0 0 78 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42.6704 0H18.2954C16.8036 0 15.3728 0.592632 14.3179 1.64752C13.263 2.70242 12.6704 4.13316 12.6704 5.625V54.375C12.6704 55.8668 13.263 57.2976 14.3179 58.3525C15.3728 59.4074 16.8036 60 18.2954 60H42.6704C44.1623 60 45.593 59.4074 46.6479 58.3525C47.7028 57.2976 48.2954 55.8668 48.2954 54.375V5.625C48.2954 4.13316 47.7028 2.70242 46.6479 1.64752C45.593 0.592632 44.1623 0 42.6704 0ZM32.4517 55.4062C31.9324 55.9362 31.2248 56.2394 30.4829 56.25C29.9324 56.2423 29.3963 56.0731 28.9411 55.7636C28.4858 55.4541 28.1314 55.0177 27.9218 54.5086C27.7121 53.9996 27.6565 53.4402 27.7618 52.8998C27.8672 52.3594 28.1287 51.8619 28.5142 51.4688C29.0507 50.9746 29.7535 50.7003 30.4829 50.7003C31.2123 50.7003 31.9151 50.9746 32.4517 51.4688C32.967 51.9945 33.2556 52.7013 33.2556 53.4375C33.2556 54.1737 32.967 54.8805 32.4517 55.4062ZM44.5454 46.875H16.4204V7.5H44.5454V46.875Z"
        fill={color}
      />
      <path
        d="M62.6421 42.25L56.7671 36.375L50.8921 42.25C50.7488 42.3574 50.6303 42.4944 50.5446 42.6517C50.4589 42.8089 50.4079 42.9827 50.3952 43.1614C50.3825 43.34 50.4084 43.5193 50.471 43.6871C50.5336 43.8549 50.6316 44.0072 50.7582 44.1339C50.8848 44.2605 51.0372 44.3585 51.205 44.4211C51.3728 44.4837 51.5521 44.5095 51.7307 44.4968C51.9093 44.4842 52.0832 44.4332 52.2404 44.3475C52.3977 44.2618 52.5346 44.1433 52.6421 44L55.5171 41.125V51.875C55.5171 52.2065 55.6488 52.5244 55.8832 52.7589C56.1176 52.9933 56.4356 53.125 56.7671 53.125C57.0986 53.125 57.4166 52.9933 57.651 52.7589C57.8854 52.5244 58.0171 52.2065 58.0171 51.875V41.125L60.8921 44C60.9995 44.1433 61.1365 44.2618 61.2938 44.3475C61.451 44.4332 61.6248 44.4842 61.8035 44.4968C61.9821 44.5095 62.1614 44.4837 62.3292 44.4211C62.497 44.3585 62.6493 44.2605 62.776 44.1339C62.9026 44.0072 63.0006 43.8549 63.0632 43.6871C63.1258 43.5193 63.1516 43.34 63.1389 43.1614C63.1263 42.9827 63.0753 42.8089 62.9896 42.6517C62.9039 42.4944 62.7854 42.3574 62.6421 42.25Z"
        fill={color}
      />
      <path
        d="M49.0739 42.8351C49.1421 42.2198 49.416 41.6455 49.8511 41.2051C49.8516 41.2046 49.8522 41.204 49.8527 41.2035L55.7241 35.3321L55.7303 35.3259L55.7364 35.3196L56.7795 34.2393L57.8099 35.2696L63.6849 41.1446L63.6964 41.1562L63.7083 41.1673C64.1721 41.6018 64.4711 42.1836 64.5545 42.8136C64.6378 43.4437 64.5003 44.0832 64.1655 44.6233C63.8306 45.1635 63.3189 45.571 62.7175 45.7765C62.1161 45.982 61.4621 45.9729 60.8666 45.7507L59.517 45.2469V46.6875V49.375V50.375H60.517H67.392C69.8121 50.375 72.1331 49.4136 73.8443 47.7024C75.5556 45.9911 76.517 43.6701 76.517 41.25C76.517 38.8299 75.5556 36.509 73.8443 34.7977C72.2077 33.1611 70.0135 32.2104 67.7082 32.1305C66.6566 30.107 65.0302 28.4329 63.0271 27.3231C60.8344 26.1083 58.3079 25.6348 55.8242 25.9731C53.3404 26.3114 51.0326 27.4435 49.2448 29.2005C47.7067 30.7122 46.6244 32.6168 46.1092 34.697C45.3624 34.5969 44.6033 34.6042 43.8552 34.7202C42.7531 34.8912 41.7 35.2943 40.7654 35.9029L41.311 36.7409L40.7654 35.9029C39.8308 36.5114 39.0361 37.3115 38.434 38.2503L39.2757 38.7902L38.434 38.2503C37.8319 39.189 37.436 40.2449 37.2725 41.3481C37.1091 42.4513 37.1818 43.5766 37.4859 44.6496C37.7901 45.7226 38.3186 46.7187 39.0366 47.5721L39.8018 46.9283L39.0366 47.5721C39.7546 48.4255 40.6457 49.1166 41.6508 49.5998C42.656 50.0829 43.7523 50.3471 44.8672 50.3747L44.8796 50.375H44.892H53.017H54.017V49.375V46.6875V45.2902L52.6944 45.741C52.1076 45.941 51.4706 45.9369 50.8865 45.7293C50.3024 45.5218 49.8055 45.123 49.4765 44.5976C49.1475 44.0723 49.0057 43.4512 49.0739 42.8351Z"
        fill={color}
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  )
}
