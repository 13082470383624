import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'

import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import Popup from 'components/Popup'
import PrivilegeListItem from 'components/PrivilegeListItem'
import { useAPI } from 'hooks/useAPI'
import { useEvent } from 'hooks/useEvent'
import { ReactComponent as ArrowLeftIcon } from 'images/icons/arrow-left.svg'

export default function PrivilegeList() {
  const { spacing, palette } = useTheme()
  const { push } = useHistory()
  const { client } = useAPI()
  const { id } = useParams()
  const { getEvent } = useEvent()
  const [error, setError] = useState(null)
  const [privileges, setPrivileges] = useState(null)

  useEffect(() => {
    getEvent(id).then(x => setPrivileges(x.privileges))
  }, [getEvent, id])

  if (privileges === null) {
    return null
  }

  if (privileges.length === 0) {
    push('/')
  }

  const updatePrivilege = data => {
    client
      .post('/vrwc/privilege_use', { privilege_id: data.privilege.id })
      .then(res => {
        const index = privileges.findIndex(x => x.privilege.id === data.privilege.id)
        const _privileges = [...privileges]
        _privileges[index] = { ...data, is_used: true }
        setPrivileges(_privileges)
      })
      .catch(e => {
        let message
        switch (e.status) {
          case 400:
            message = '存在しないか有効ではない特典です。'
            break
          case 409:
            message = '既に利用された特典です。'
            break
          default:
            message = '特典の利用に失敗しました、大変お手数ですが時間を空けてお試しください。'
        }
        setError({ message })
      })
  }

  return (
    <>
      <div>
        <Link
          to="/users/me"
          style={{ padding: spacing(5, 3), display: 'flex', alignItems: 'center' }}
        >
          <ArrowLeftIcon />
          <Typography style={{ marginLeft: spacing(2), color: palette.link }}>
            マイページ
          </Typography>
        </Link>
        {privileges.map(x => (
          <PrivilegeListItem key={x.privilege.id} data={x} onSubmit={() => updatePrivilege(x)} />
        ))}
      </div>
      {error && (
        <Popup
          onClose={() => setError(null)}
          title="エラーが発生しました"
          description={error.message}
          primaryText="OK"
          onClickPrimary={() => setError(null)}
        />
      )}
    </>
  )
}
