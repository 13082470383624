import React, { useMemo } from 'react'

import { useTheme } from '@material-ui/core/styles'

import NewsListItem from 'components/NewsListItem'
import SectionHeader from 'components/SectionHeader'
import { useNews } from 'hooks/useNews'

export default function NewsList({ limit }) {
  const { palette } = useTheme()
  const { news } = useNews()

  const filtered = useMemo(() => {
    return limit === undefined ? news : news.slice(0, limit)
  }, [limit, news])

  return (
    <div style={{ background: palette.background.paper }}>
      <SectionHeader primary="NEWS" />
      {filtered.map(item => (
        <NewsListItem key={item.url} item={item} />
      ))}
    </div>
  )
}
