import React, { useEffect } from 'react'

import Button from '@material-ui/core/Button'

import { useAuth } from 'hooks/useAuth'

export default function FacebookLogin({ onError }) {
  const { signInWithSns } = useAuth()
  useEffect(() => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        version: 'v17.0',
      })
    }
  }, [])

  const signIn = () => {
    window.FB.login(response => {
      if (response.status === 'connected') {
        const { accessToken, data_access_expiration_time, userID } = response.authResponse
        signInWithSns({
          provider: 'Facebook',
          provider_user_id: userID,
          access_token: accessToken,
          access_token_expires: data_access_expiration_time,
          access_token_secret: ' ',
        }).catch(onError)
      }
    })
  }

  return (
    <Button style={{ padding: 0, marginBottom: 12 }} onClick={signIn}>
      <img
        src={require('images/sns/facebook.png')}
        alt="Sign in with Facebook"
        style={{ width: 210, height: 40 }}
      />
    </Button>
  )
}
