import React from 'react'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

import { ReactComponent as ArrowIcon } from 'images/icons/arrow-right.svg'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    borderRadius: 22,
    background: '#fff',
  },
  button: {
    width: '100%',
    height: 40,
    borderRadius: 20,
    outline: 'none',
    position: 'relative',
    '&.MuiButton-contained': {
      border: '1px solid #fff',
      '&.Mui-disabled': {
        borderColor: '#fff',
        background: '#cecece',
        color: 'rgba(0,0,0,0.15)',
      },
    },
    '&.MuiButton-containedPrimary': {
      background: process.env.REACT_APP_COLOR_BUTTON_FILL,
    },
    '&.MuiButton-outlined': {
      border: `2px solid ${theme.palette.primary.main}`,
      padding: theme.spacing(1),
      '&.Mui-disabled': {
        borderColor: 'rgba(0,0,0,0.15)',
        background: '#cecece',
        color: 'rgba(0,0,0,0.15)',
      },
    },
    '& .MuiButton-label': {
      pointerEvents: 'none',
    },
  },
  icon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(3),
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
}))

export default function CustomButton({
  id,
  style,
  type,
  disabled,
  variant,
  arrow,
  onClick,
  children,
}) {
  const classes = useStyles()
  const wrapperStyle =
    variant === 'contained'
      ? {
          padding: 2,
          background: disabled ? 'rgba(0,0,0,0.15)' : process.env.REACT_APP_COLOR_BUTTON_BORDER,
        }
      : {}

  return (
    <div className={classes.wrapper} style={{ ...style, ...wrapperStyle }}>
      <Button
        id={id || ''}
        color="primary"
        variant={variant || 'outlined'}
        type={type || 'button'}
        className={classes.button}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
        {arrow && <ArrowIcon className={classes.icon} />}
      </Button>
    </div>
  )
}
